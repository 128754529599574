/* Style for the entire scrollbar */
::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }
  
  /* Style for the scrollbar track (the area the thumb slides within) */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* light gray background */
  }
  
  /* Style for the scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background: skyblue; /* gray thumb */
    border-radius: 20px;
  }
  
  /* Hover effect on scrollbar thumb */
  ::-webkit-scrollbar-thumb:hover {
    background: skyblue; /* dark gray thumb */
  }
  